import React from 'react'

import Layout from 'layouts/Page'
import SEO from 'components/shared/SEO'

const PressPage = () => <div />

export default ({ location }) => (
  <Layout navbar="main" location={location}>
    <SEO title="Press" />
    <PressPage />
  </Layout>
)
